import fetch from "unfetch";
import { apiUrl } from "@/config";
import { merge } from "lodash";
import { pusher } from "@/pusher";
export async function login(credential) {
  const request = merge(credential, {
    grant_type: "password",
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    client_id: 2,
    scope: "*",
  });

  let response = await fetch(`${apiUrl}/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(request),
  });

  return response.json();
}

export async function log(args) {
  const request = merge(args, {
    devlog: "API-LOG",
  });
  let response = await fetch(`${apiUrl}/quicklog`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(request),
  });

  return response.json();
}

export async function getUser(authorization) {
  let response = await fetch(`${apiUrl}/api/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
    },
  });

  return response.json();
}
export async function getWorkspaces(authorization, query) {
  let response = await fetch(process.env.VUE_APP_GRAPH_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authorization}`,
    },
    body: JSON.stringify({
      query,
      cacheKey: "Workspaces2Query",
    }),
  });

  return response.json();
}
export async function exportMessage(workspace, authorization) {
  let response = await fetch(
    `${apiUrl}/api/createlink?workspace_id=${workspace}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  return response.json();
}

export async function uploadtoS3(formData) {
  let response = await fetch(`${apiUrl}/api/imageupload`, {
    method: "POST",
    body: formData,
  });

  return response.json();
}

export async function uploadOutSourceFile(
  { fileData, workspace_id },
  authorization,
  progress,
  cb
) {
  const makeObj = [];
  fileData.forEach((file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workspace_id", workspace_id);

    fetch(`${apiUrl}/api/media`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${authorization}`,
        Pusher: pusher.connection.socket_id,
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((res) => {
          makeObj.push(res.data);
          progress(res);
          if (makeObj.length === fileData.length) {
            cb(makeObj);
          }
        });
      }
    });
  });
}

export async function exportTimeLogs(
  workspace,
  authorization,
  taskId = 0,
  isExportCompany = false,
  user_id = 0,
  date = "",
  hourlyRate = 0,
) {
  let response = await fetch(
    `${apiUrl}/api/createtimeloglink?workspace_id=${workspace}&task_id=${taskId}&is_export_company=${isExportCompany}&user_id=${user_id}&date=${date}&timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&hourly_rate=${hourlyRate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  return response.json();
}

export async function getCustomerPortalUrl(authorization, workspace) {
  let response = await fetch(`${apiUrl}/api/customer-portal?workspace_id=${workspace}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
    },
  });

  return response.json();
}

export async function getBillingPortalUrl(authorization, workspace, priceId, coupenCode = "") {
  let response = await fetch(`${apiUrl}/api/billing-portal?workspace_id=${workspace}&price_id=${priceId}&coupenCode=${coupenCode}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
    },
  });

  return response.json();
}

export async function exportMedia(
  folder_id,
  authorization
  ) {
  let response = await fetch(
    `${apiUrl}/api/create-media-link?folder_id=${folder_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  return response.json();
}

export async function createWorkspace(args, authorization) {
  let response = await fetch(`${apiUrl}/api/create-workspace`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function getChannelList(args, authorization) {
  let response = await fetch(`${apiUrl}/api/slack-channels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function getAsanaUserToken(args, authorization) {
  let response = await fetch(`${apiUrl}/api/asana-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function getSpaceList(args, authorization) {
  let response = await fetch(`${apiUrl}/api/clickup-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function mondayLogin(args, authorization) {
  let response = await fetch(`${apiUrl}/api/monday-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function exportBoardTask(workspace, authorization) {
  let response = await fetch(
    `${apiUrl}/api/createtasklink?workspace_id=${workspace}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );
  return response.json();
}

export async function exportDocumentPDF(args, authorization) {
  let response = await fetch(`${apiUrl}/api/document-pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function exportDocument(args, authorization) {
  let response = await fetch(`${apiUrl}/api/export-document`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}

export async function deleteDocumentPDF(args, authorization) {
  let response = await fetch(`${apiUrl}/api/delete-document-pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorization}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify(args),
  });

  return response.json();
}