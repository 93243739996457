class ErrorHandler {
  getErrorMessage(error, context, defaultMessage) {
    const message = error.graphQLErrors[0].message;
    if (message === "Validation error." || message === "Internal server error")
      return context.$t(defaultMessage);
    return context.$t(`message.${message}`);
  }
}

export default new ErrorHandler();
